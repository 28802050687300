var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[(!_vm.toggle)?_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"card-text"},[_c('span',{staticClass:"mx-1 pointer float-right",on:{"click":function($event){_vm.toggle = !_vm.toggle}}},[_c('fa',{staticClass:"text-warning",attrs:{"icon":"pencil-alt"}})],1),_c('div',{},[_vm._v(" "+_vm._s(_vm.item.inventory.code)+" ("+_vm._s(_vm.item.inventory.name)+") ")]),_c('small',[_c('div',{staticClass:"pre-line my-2 text-primary"},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),(_vm.costOfSales.length > 0)?_c('div',{staticClass:"text-danger"},[_vm._v(" ต้นทุนขาย: "),_c('ul',{staticClass:"my-0"},_vm._l((_vm.costOfSales),function(child){return _c('li',{key:child.id},[_vm._v(" "+_vm._s(child.account.code)+" ("+_vm._s(child.account.name)+") ")])}),0)]):_vm._e(),(_vm.sellAccountReceivable.length > 0)?_c('div',{staticClass:"text-success"},[_vm._v(" ขาย: "),_c('ul',{staticClass:"my-0"},_vm._l((_vm.sellAccountReceivable),function(child){return _c('li',{key:child.id},[_vm._v(" "+_vm._s(child.account.code)+" ("+_vm._s(child.account.name)+") ")])}),0)]):_vm._e()])])]):_vm._e(),(_vm.toggle)?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-text"},[_c('div',{staticClass:"form-row"},[_c('sgv-input-textarea',{staticClass:"col-12",attrs:{"label":"ชื่อ","validations":[
            {text: 'required!', value: _vm.$v.formData.name.$dirty && !_vm.$v.formData.name.required}
          ]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('DocConfigFormInventory',{ref:"inventory",staticClass:"col-12",attrs:{"docType":_vm.docType,"templateType":_vm.templateType,"select":"id","types":_vm.menu.inventoryTypes,"validations":[
            {text: 'required!', value: _vm.$v.formData.inventoryId.$dirty && !_vm.$v.formData.inventoryId.required}
          ]},model:{value:(_vm.formData.inventoryId),callback:function ($$v) {_vm.$set(_vm.formData, "inventoryId", $$v)},expression:"formData.inventoryId"}})],1),(_vm.item.id)?[_c('ul',{staticClass:"nav nav-pills justify-content-center mb-3"},_vm._l((_vm.tabs),function(tab){return _c('li',{key:tab.value,staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();_vm.selectedTab = tab.value}}},[_c('a',{staticClass:"nav-link",class:{'active': _vm.selectedTab === tab.value},attrs:{"href":"#"}},[_vm._v(" "+_vm._s(tab.text)+" ")])])}),0),(_vm.selectedTab === 'costOfSales')?_c('div',[_c('div',{staticClass:"form-row"},[_c('DocConfigFormAccount',{staticClass:"col-12",attrs:{"label":"ต้นทุนขาย","select":"id","docType":_vm.docType,"templateType":_vm.templateType,"validations":[
                {text: 'required!', value: _vm.$v.costOfSalesFormData.accountId.$dirty && _vm.$v.costOfSalesFormData.accountId.$error}
              ]},model:{value:(_vm.costOfSalesFormData.accountId),callback:function ($$v) {_vm.$set(_vm.costOfSalesFormData, "accountId", $$v)},expression:"costOfSalesFormData.accountId"}})],1),_c('button',{staticClass:"btn btn-success mb-3",attrs:{"type":"button"},on:{"click":_vm.addCostOfSales}},[_vm._v(" เพิ่ม ")]),(_vm.costOfSales.length > 0)?_c('div',{staticClass:"mb-3"},[_c('small',[_c('ul',{staticClass:"my-0"},_vm._l((_vm.costOfSales),function(child){return _c('li',{key:child.id},[_c('span',{staticClass:"pointer mr-1",on:{"click":function($event){return _vm.destroyData(child.id, _vm.item.id)}}},[_c('fa',{staticClass:"text-danger",attrs:{"icon":"trash"}})],1),_vm._v(" "+_vm._s(child.account.code)+" ("+_vm._s(child.account.name)+") ")])}),0)])]):_vm._e()]):_vm._e(),(_vm.selectedTab === 'sellAccountReceivable')?_c('div',[_c('div',{staticClass:"form-row"},[_c('DocConfigFormAccount',{staticClass:"col-12",attrs:{"label":"ต้นทุนขาย","select":"id","docType":_vm.docType,"templateType":_vm.templateType,"validations":[
                {text: 'required!', value: _vm.$v.sellAccountReceivableFormData.accountId.$dirty && _vm.$v.sellAccountReceivableFormData.accountId.$error}
              ]},model:{value:(_vm.sellAccountReceivableFormData.accountId),callback:function ($$v) {_vm.$set(_vm.sellAccountReceivableFormData, "accountId", $$v)},expression:"sellAccountReceivableFormData.accountId"}})],1),_c('button',{staticClass:"btn btn-success mb-3",attrs:{"type":"button"},on:{"click":_vm.addSellAccountReceivable}},[_vm._v(" เพิ่ม ")]),(_vm.sellAccountReceivable.length > 0)?_c('div',{staticClass:"mb-3"},[_c('small',[_c('ul',{staticClass:"my-0"},_vm._l((_vm.sellAccountReceivable),function(child){return _c('li',{key:child.id},[_c('span',{staticClass:"pointer mr-1",on:{"click":function($event){return _vm.destroyData(child.id, _vm.item.id)}}},[_c('fa',{staticClass:"text-danger",attrs:{"icon":"trash"}})],1),_vm._v(" "+_vm._s(child.account.code)+" ("+_vm._s(child.account.name)+") ")])}),0)])]):_vm._e()]):_vm._e()]:_vm._e(),(_vm.isForm)?_c('button',{staticClass:"btn btn-success",attrs:{"type":"button"},on:{"click":_vm.updateData}},[_vm._v(" เพิ่ม ")]):_vm._e(),(!_vm.isForm)?_c('button',{staticClass:"btn btn-warning",attrs:{"type":"button"},on:{"click":_vm.updateData}},[_vm._v(" แก้ไข ")]):_vm._e(),(_vm.toggle)?_c('button',{staticClass:"btn btn-primary ml-2",attrs:{"type":"button"},on:{"click":_vm.cancelData}},[_vm._v(" ยกเลิก ")]):_vm._e(),(!_vm.isForm)?_c('button',{staticClass:"btn btn-danger ml-2 float-right",attrs:{"type":"button"},on:{"click":function($event){return _vm.destroyData(_vm.item.id)}}},[_vm._v(" ลบ ")]):_vm._e()],2)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }