import gql from 'graphql-tag'


const orderResponse = `
  id type name
  qty price totalPrice
  unitId unit {id name}
  stocks {
    id qty docId parentId orderId isPrinted
    inventoryId inventory {
      id code name supplierSku
      unitId unit {id name}
    }
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
  ledgers {
    id docId stockId orderId amount
    accountId account {id code name}
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {${orderResponse}}
}`

export const CREATE_ORDER = (templateType) => gql`mutation CREATE_ORDER ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  createOrder: createDoc${templateType}Order (docType: $docType, docId: $docId, input: $input) {${orderResponse}}
}`

export const DESTROY_ORDER = (templateType) => gql`mutation DESTROY_ORDER ($docType: String!, $orderId: Int!) {
  destroyOrder: destroyDoc${templateType}Order (docType: $docType, orderId: $orderId) {id}
}`

export const WATCH_ORDER_UPDATED = (templateType) => gql`subscription WATCH_ORDER_UPDATED ($docType: String!, $docId: Int!) {
  orderUpdated: watchDoc${templateType}OrderUpdated (docType: $docType, docId: $docId) {${orderResponse}}
}`

export const WATCH_ORDER_DESTROYED = (templateType) => gql`subscription WATCH_ORDER_DESTROYED ($docType: String!, $docId: Int!) {
  orderDestroyed: watchDoc${templateType}OrderDestroyed (docType: $docType, docId: $docId) {id parentId}
}`

export const LIST_ORDER_INVENTORY = (templateType) => gql`query LIST_ORDER_INVENTORY ($docType: String!, $contactId: Int!, $q: FilterInput) {
  inventories: listDoc${templateType}OrderInventory (docType: $docType, contactId: $contactId, q: $q) {
    id type name isDefault
    inventoryId inventory {id type code name}
  }
}`

export const LIST_ORDER_EXCHANGE = (templateType) => gql`query LIST_ORDER_EXCHANGE ($docType: String!, $contactId: Int!, $q: FilterInput) {
  inventories: listDoc${templateType}OrderExchange (docType: $docType, contactId: $contactId, q: $q) {
    id type name isDefault
    inventoryId inventory {id type code name}
  }
}`

export const LIST_ORDER_OTHER_CONFIG = (templateType) => gql`query LIST_ORDER_OTHER_CONFIG ($docType: String!, $contactId: Int!) {
  others: listDoc${templateType}OrderOtherConfig (docType: $docType, contactId: $contactId) {
    id type name isDefault vatPrice discountPrice
  }
}`
